import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";

import InformationCardUser from "../components/InformationCardUser";
/**
 * Step 1: Selected the profile type
 */
const ProfileChoiceStepUser = ({ profile, handleSelectUser, validStep, handleNext }) => {
	const dispatch = useDispatch();

	// Handle select profile
	const selectProfile = user => {
		handleSelectUser(user.userID, user.picture);
		validStep();
		handleNext();
	};

	const [userList, setUserList] = useState([]);
	const { user } = useSelector(state => state);

	const [search, setSearch] = useState("");

	const filterList = list => {
		if (search.length === 0) {
			return list;
		}
		return list.filter(
			c =>
				c.userID.toLowerCase().includes(search.toLowerCase()) ||
				c.first.toLowerCase().includes(search.toLowerCase()) ||
				c.last.toLowerCase().includes(search.toLowerCase())
		);
	};

	useEffect(() => {
		if (profile?.userID) {
			validStep();
		}
		const onSuccess = res => {
			setUserList(res.users);
		};

		dispatch(FormActions.getUsers("user", user.accountRole, onSuccess));
	}, []);

	return (
		<MDBox>
			<MDInput
				label="Rechercher"
				fullWidth
				value={search}
				onChange={e => setSearch(e.target.value)}
			/>
			<MDBox display="flex" flexDirection="column" justifyContent="space-evenly">
				{filterList(userList || [])
					.sort((a, b) => a.userID.localeCompare(b.userID))
					.map((c, index) => {
						return (
							<InformationCardUser
								element={c}
								onClick={() => selectProfile(c)}
								selected={profile && profile.userID === c.userID}
							/>
						);
					})}
			</MDBox>
		</MDBox>
	);
};

export default ProfileChoiceStepUser;
