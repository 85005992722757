import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import ProductsActions from "redux-react/actions/productsActions";
import MDBox from "components/Basics/MDBox";
import InformationCard from "../components/InformationCard";
/**
 * Step 1: Selected the product type
 */
const ProductChoiceStep = ({ product, handleSelectProduct, validStep, handleNext }) => {
	const dispatch = useDispatch();

	// Handle select product
	const selectProduct = product => {
		handleSelectProduct(product);
		validStep();
		handleNext();
	};

	const [productsList, setProductsList] = useState([]);

	useEffect(() => {
		if (product?.type) {
			validStep();
		}
		const onSuccess = res => {
			setProductsList(res.products);
		};
		dispatch(ProductsActions.getProducts(onSuccess));
	}, []);

	return (
		<MDBox>
			<MDBox mt={3} display="flex" flexDirection="column" justifyContent="space-evenly">
				{productsList?.length > 0 && (
					<>
						{productsList.map((c, index) => {
							return (
								<InformationCard
									element={c}
									onClick={() => selectProduct(c)}
									selected={product && product.code === c.code}
								/>
							);
						})}
					</>
				)}
			</MDBox>
		</MDBox>
	);
};

export default ProductChoiceStep;
