import { Avatar } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
/**
 * Information card : Title / Description / Image, click handler
 */
const InformationCardAssistant = ({ onClick, selected, element }) => {
	return (
		<MDBox
			mt={0.5}
			className="cardGraphChoice"
			onClick={onClick}
			p={1.5}
			borderRadius="xl"
			style={{
				backgroundColor: selected ? "#1A73E8" : ""
			}}
			sx={{
				transition: "background-color 0.1s ease-in-out",
				"&:hover": {
					backgroundColor: "#eeeeee"
				}
			}}
		>
			<MDBox display="flex" alignItems="center">
				<MDBox flex="1">
					<MDTypography variant="h6" fontSize="small" color={selected ? "white" : "dark"}>
						{i18n.t(element.name)}
					</MDTypography>
					<MDTypography variant="body2" fontSize="small" color={selected ? "white" : "dark"}>
						{i18n.t(element.description)}
					</MDTypography>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default InformationCardAssistant;
