import { api } from "./api";

const actions = {
	insertRule: (data, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/rule/add`,
					data,
					onSuccess,
					onFailure
				})
			);
		};
	}
};

export default actions;
