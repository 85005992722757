import lod_ from "lodash";
import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Icon,
	IconButton,
	Tooltip
} from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { LittleForm } from "components/Custom/LittleForm";
import MDInput from "components/Basics/MDInput";
import MDButton from "components/Basics/MDButton";
import { useState } from "react";

function getLabel(item, language) {
	let itemLabels = item.label;
	if (!itemLabels) return null;
	let label = itemLabels[language];
	let firstKey = Object.keys(itemLabels)[0];
	if (!label) label = itemLabels[firstKey];
	return label;
}

const ObjectMap = ({ path: rootPath, item, values = {}, userLanguage, onChange }) => {
	const [temporaryCode, setTemporaryCode] = useState("");

	let label = getLabel(item, userLanguage);

	const deleteCatalog = code => {
		let copyValues = { ...values };
		delete copyValues[code];
		const event = {
			target: {
				name: rootPath,
				value: copyValues
			}
		};
		onChange(event);
	};

	const addCatalog = code => {
		let copyValues = { ...values, [code]: {} };
		const event = {
			target: {
				name: rootPath,
				value: copyValues
			}
		};
		onChange(event);
		setTemporaryCode("");
	};

	const updateField = (code, path, value) => {
		// Clone values
		let copyValues = { ...values };
		let copyIndex = { ...copyValues[code] };
		// Set new value
		lod_.set(copyIndex, path, value);
		// Update values
		copyValues[code] = copyIndex;
		// Generate event
		const event = {
			target: {
				name: rootPath,
				value: copyValues
			}
		};
		onChange(event);
	};

	return (
		<Accordion className="customAccordion" sx={{ mb: 1 }} style={{ boxShadow: "none" }}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{label}
			</AccordionSummary>
			<AccordionDetails>
				{Object.entries(values).map(([key, value], index) => {
					return (
						<MDBox
							key={index}
							sx={{ mt: index > 0 ? 3 : 0 }}
							bgColor="light"
							p={2}
							borderRadius="lg"
						>
							<MDBox
								display="flex"
								flexDirection="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<MDBox>{key}</MDBox>
								<Tooltip placement="top" title={`Supprimer "${key}"`}>
									<IconButton style={{ color: "red" }} onClick={() => deleteCatalog(key)}>
										<Icon>delete</Icon>
									</IconButton>
								</Tooltip>
							</MDBox>
							<LittleForm
								object={item.items}
								metadatasSkeleton={value}
								handleChange={(path, value) => updateField(key, path, value)}
							/>
						</MDBox>
					);
				})}
				<Divider />
				<MDBox sx={{ mt: 3 }} display="flex" flexDirection="row" alignItems="center">
					<MDInput
						label="Nouveau catalogue"
						placeholder="Code du catalogue"
						fullWidth
						value={temporaryCode}
						onChange={e => {
							if (e.target.value.includes(" ")) return;
							setTemporaryCode(e.target.value);
						}}
						onKeyPress={e => {
							if (e.key === "Enter") {
								addCatalog(temporaryCode);
							}
						}}
					/>
					<MDButton
						sx={{ ml: 1 }}
						variant="contained"
						color="info"
						disabled={temporaryCode === ""}
						onClick={() => {
							addCatalog(temporaryCode);
						}}
					>
						Ajouter
					</MDButton>
				</MDBox>
			</AccordionDetails>
		</Accordion>
	);
};

export default ObjectMap;
